.lunchbuffetbg {
  margin: 80px 0 80px 0;
  width: 100%;
  height: 60vh;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 10.9), rgba(0, 0, 0, 0.5)), url(../assets/images/testimonial/parralex2.png);
  background-attachment: fixed;
  background-blend-mode: color-burn;
}

.lunchbuffetbg .react-multi-carousel-dot button {
  border-color: #fff !important;
}

.lunchbuffetbg .react-multi-carousel-dot--active button {
  background: #fff !important;
}

.testimonial-slid {
  color: #fff;
}

.testimonial-slid p {
  text-align: center !important;
  font-size: 1.1rem;
}

@media (min-width: 992px) {
  .testimonial-slid p {
    font-size: 1.8rem;
  }

  .testimonial-slid span {
    font-size: 1.3rem;
  }
}

@media (max-width:600px) {
  .lunchbuffetbg {
    margin: 70px 0 0;
    width: 100%;
    height: 40vh;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 10.9), rgba(0, 0, 0, 0.5)), url(../assets/images/testimonial/parralex2.png);
    background-attachment: fixed;
    background-blend-mode: color-burn;
  }
}