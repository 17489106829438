.floating-button {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.floating-button-cart {
  display: flex;
  align-items: center;
  gap: 10px;
  /* min-width: 500px; */
  justify-content: space-between;
  margin-right: 1px;
}

/* .floating-button-cart,
.floating-button {
  box-shadow: 0 0 10px #e3e3e366;
} */
.cart-button {
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 500px;
  justify-content: space-between;
}

.rupee-icon {
  font-size: 15px;
  margin-top: -3px;
}

.offcanvas.offcanvas-end {
  width: 35%;
}

/* .fixed-bottom-cart{
  transition: 1s;
  transform: translateY(50px);
}
.fixed-bottom-cart-active{
  transform: translateY(0);
} */
.free-dish-card {
  border: 2px dashed #008000;
  padding: 10px;
  cursor: not-allowed;
  opacity: .5;
}

.free-dish-card>p {
  color: #ff0000;
}

.free-dish-card.active {
  opacity: 1;
  cursor: auto;
}


.free-btn {
  padding: 5px 10px;
  background: #008000 !important;
  color: var(--theme-color-white) !important;
  border: none !important;
}

@media (max-width: 1240px) {
  .floating-button-cart {
    min-width: fit-content;
  }

  .item-category-filter {
    max-width: fit-content !important;
  }
}

@media (max-width: 1120px) {
  .offcanvas.offcanvas-end {
    width: 60%;
  }
}

.offcanvas .offcanvas-body {
  min-height: unset;
  overflow-y: auto;
  padding-bottom: 0;
}

.offcanvas-close,
.offcanvas-close:hover {
  color: var(--color-theme);
  text-decoration: none;
  text-transform: capitalize;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.saving {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 14px;
}

.saving .save-thumb {
  font-size: 20px;
  margin-top: -8px;
  margin-right: 5px;
}

.saving .rupee {
  font-size: 14px;
  margin-top: -5px;
}

.saving .saving-amount {
  font-size: 16px;
}

.backbtn-quickselection a {
  color: var(--color-theme);
}

.alert {
  padding: 5px;
  margin-bottom: 10px;
}

.offcanvas .address-card {
  /* margin-right: 10px;
  position: relative; */
  /* max-height: 300px; */
  height: 100%;
  /* overflow-y: auto; */
}

.offcanvas .address-card .swiper-slide {
  height: auto !important;
}

.offcanvas .address-list .react-multiple-carousel__arrow {
  opacity: 0;
}

.offcanvas .address-card .selected-address {
  position: absolute;
  right: 10px;
  top: 5px;
}

.rupee-icon-bill {
  font-size: 80%;
  margin-top: -3px;
}

.address-map>div {
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
}

.address-map>div,
.address-map>div>div,
.address-map>div>div>div,
.address-map>div>div>div>div {
  position: relative !important;
}

.seperator {
  display: inline-block;
}

.btn-place-order {
  display: flex;
  justify-content: center;
  bottom: 0px;
  z-index: 99;
}

.acc-inactive {
  opacity: 0.5;
  pointer-events: none;
}

.acc-active {
  opacity: 1;
  pointer-events: all;
}

.browse-menu-block {
  overflow-y: auto;
  max-height: 400px;
}

.browse-menu-list {
  list-style-type: none;
  padding-left: 0;
  margin-left: 0;
}

.browse-menu-list li {
  padding: 5px;
  margin: 5px 0;
  border-radius: 5px;
}

.browse-menu-list li:hover {
  background-color: var(--theme-bg-light);
}

.browse-menu-list li a {
  color: var(--theme-text-black);
  text-decoration: none;
}

.offcanvas.offcanvas-bottom {
  height: 80vh;
}

.address-icon {
  padding: 5px 8px !important;
  font-size: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .offcanvas .address-card {
    padding: 10px;
    margin: 0 10px;
  }

  .address-map {
    margin-bottom: 20px;
  }

  .offcanvas.offcanvas-end {
    width: 100%;
  }

  .floating-button {
    flex: 1;
  }

  .floating-button {
    display: block;
    align-items: center;
    gap: 10px;
    justify-content: center;
    margin-bottom: 10px;
    margin-left: auto;
  }

  .floating-button-cart {
    display: flex;
    align-items: center;
    gap: 10px;
    min-width: 100%;
    justify-content: space-between;
  }

  .total-item-amount {
    font-size: 17px;
  }

  .saving,
  .saving .saving-amount {
    font-size: 12px;
  }

  .saving .rupee {
    font-size: 11px;
    margin-top: -3px;
  }

  .accordion-item .accordion-button {
    padding: 15px;
  }

  .accordion-body {
    padding: 15px;
  }

  .floating-button-cart {
    display: block;
  }

  .total-item-amount {
    text-align: center;
  }

  .seperator {
    display: none;
  }

  .btnicons {
    width: 20px;
  }
}

.non-veg-icon svg {
  color: #ff0000 !important;
  border: 1px solid #ff0000 !important;
  padding: 2px;
  font-size: 1rem;
}

.veg-icon svg {
  color: #008000 !important;
}

.fixed-bottom-tabs .ant-tabs {
  position: absolute;
  top: -100%;
  opacity: 0;
}

.fixed-bottom-tabs-active .ant-tabs {
  display: block;
  visibility: visible;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: var(--theme-color-white);
  padding: 10px;
  opacity: 1;
  transition: .5s;
}



/* .fixed-bottom-tabs .ant-tabs .ant-tabs-tab{
display: none;
visibility: hidden;
padding: 0;
background: transparent;
} */

.ant-tabs .ant-tabs-tab {
  margin: 0 !important;
  margin-right: 10px !important;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid var(--color-theme);
  width: 100%;
  padding-right: 1rem;
}

.fixed-bottom-tabs .ant-tabs-tab-btn {
  color: var(--color-theme) !important;
}

.fixed-bottom-tabs .ant-tabs-nav::before {
  border: none !important;
}

.fixed-bottom-tabs .ant-tabs-tab-active {
  background: var(--color-theme) !important;
}

.fixed-bottom-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}

.fixed-bottom-tabs .ant-tabs-nav-operations {
  display: none !important;
}

.fixed-bottom-tabs .ant-tabs-nav {
  margin-bottom: 0;
}

.fixed-bottom-tabs .ant-tabs-ink-bar {
  display: none !important;
}

.fixed-bottom-tabs :where(.css-dev-only-do-not-override-fpg3f5).ant-tabs .ant-tabs-ink-bar {
  display: none !important;
  background: var(--color-theme) !important;
}

.floating-button-cart {
  min-width: fit-content;
}

/* @media (max-width: 1300px) {
  .fixed-bottom-tabs .ant-tabs {
    width: 20%;
    padding-right: 1rem;
  }
} */


@media (max-width: 568px) {
  .fixed-bottom-tabs {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: end;
  }


  .fixed-bottom {
    background-color: #fff;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
    width: 100%;
  }

  .fixed-bottom-tabs .ant-tabs {
    position: static;
    padding: 0;
    width: 100%;
    padding-right: 6.5rem !important;
    opacity: 1;
  }

  .fixed-bottom-tabs .ant-tabs .ant-tabs-tab {
    border: none;
  }

  .fixed-bottom button:first-child,
  .fixed-bottom a {
    width: 50%;
    display: inline;
    margin-bottom: 5px;
  }

  .fixed-bottom button:last-child {
    width: 100%;
    justify-content: center;
  }

  .fixed-bottom button:first-child {
    position: absolute;
  }

  .fixed-bottom-tabs {
    position: relative;
    width: 100%;
  }

  .fixed-bottom-tabs .ant-tabs .ant-tabs-tab {
    margin: 0 !important;
    margin-right: 10px !important;
    padding: 10px;
    border-radius: 8px;
  }

  .fixed-bottom-tabs .ant-tabs-tab-btn {
    color: var(--color-theme) !important;
  }

  .fixed-bottom-tabs .ant-tabs-nav::before {
    border: none !important;
  }

  .fixed-bottom-tabs .ant-tabs-tab-active {
    background: var(--color-theme) !important;
  }

  .fixed-bottom-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff !important;
  }

  .fixed-bottom-tabs .ant-tabs-nav-operations {
    display: none !important;
  }

  .floating-button-cart {
    margin-bottom: -6rem;
    transition: 1s;
  }

  .floating-button-cart-btn {
    margin-bottom: 0;
    margin-top: 10px;
  }

  /* .fixed-bottom-tabs
    :where(.css-dev-only-do-not-override-fpg3f5).ant-tabs
    .ant-tabs-tab.ant-tabs-tab-active
    .ant-tabs-tab-btn {
    color: var(--color-theme) !important;
  } */

  .floating-menu-button {
    width: auto !important;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 0.8rem;
    margin-bottom: 0 !important;
    padding: 12px !important;
    font-size: 16px;
  }
}

.modal-sec-body input {
  padding-left: 2rem;
}

.modal-sec-img {
  text-align: center;
}

/* .modal-sec-img img {
  width: 130px;
  height: 130px;
  margin-top: 1rem;
  object-fit: contain;
} */

.modal-sec-icon-2,
.modal-sec-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

/* .modal-sec-icon-2{
  left: 10px;
} */

.btn-tips {
  padding: 5px 15px !important;
}

.clear-tip-btn {
  cursor: pointer;
  color: var(--color-theme);
  font-weight: bold;
}

.wrong-address-color {
  color: #ff0000 !important;
}

.no-delivery-border {
  border: 1px solid #ff0000 !important;
}

.saveProceedbtnnodeliveryzone {
  background-color: #b1b1b1;
  color: white;
  border-radius: 5px !important;
  border: none !important;
  padding: 10px;
  width: 100%;
  cursor: default;
}

.payment-modal-header .btn-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.recipe-best .Mild {
  color: #81dd28;
}

.recipe-best .Medium {
  color: #ffd72b;
}

.recipe-best .Medium-Hot {
  color: #f26906;
}

.recipe-best .Hot {
  color: #bf0514;
}

.recipe-best .Extra-Hot {
  color: #a10b1b;
}

.thankyou-modal img {
  width: 135px;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.thankyou-modal p {
  font-weight: 600;
}

.thankyou-modal h3 {
  margin-bottom: 2rem;
  font-weight: 400;
}
.small.mt-2.mb-3{
  line-height: 1.4;
}
/* @media (max-width:568px) {
  .thankyou-modal img {
    width: 100px;
  }
} */

.add-address-offcanvas-btn {
  background: #36034A !important;
  color: #fff !important;
  border-radius: 10px;
  padding: 10px;
  font-size: 15px;
}

.add-address-offcanva-checkbox .form-check-input {
  border: 2px solid #0000009e;
}

.coupon-accordian .accordion-button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.coupon-card-body {
  padding: .7rem;
}

.view-all-coupon-text {
  position: absolute;
  bottom: 15px;
  right: 47px;
  color: var(--color-theme);
}

.coupon-accordian .accordion-button::after {
  margin-bottom: -8px;
}

@media (max-width:1168px) {
  .coupon-accordian .accordion-button::after {
    margin-bottom: 0;
  }
}

.delivery-option-box {
  display: flex;
  position: relative;
  background: #e3e3e3;
  padding: 10px;
  justify-content: space-between;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.delivery-option-box::before {
  content: "";
  width: 50%;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: var(--color-theme);
  height: 70%;
  border-radius: 0.5rem;
}

.delivery-option-btn .form-check-input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.delivery-option-btn {
  padding: 10px;
  position: relative;
  z-index: 2;
  width: 100%;
  text-align: center;
}

.delivery-option-btn h6 {
  font-weight: bold;
}

.delivery-option-box.active::before {
  left: 95%;
  transform: translate(-95%, -50%);
}

.delivery-option-box.active .delivery-option-btn h6 {
  color: #fff !important;
}

.delivery-option-box.active .delivery-option-btn-one h6 {
  color: #000 !important;
}

.coupon-applied-box {
  position: fixed;
  padding: 2rem;
  background: var(--theme-color-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.5rem;
  width: 27%;
  z-index: 99999;
  font-size: 2rem;
  /* text-transform: capitalize; */
}

.coupon-applied-box-overlay {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.477);
  overflow: hidden;
}

.coupon-applied-box img {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -9;
}

.coupon-applied-box .icon {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e3e3e3;
  color: var(--color-theme);
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 1rem;
  position: relative;
}

.coupon-applied-box p {
  position: relative;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 0;
}

.coupon-close-btn {
  position: absolute;
  top: -8px;
  right: 0;
  cursor: pointer;
}

.coupon-applied-box .position-relative {
  margin-top: -75px;
}

.coupon-applied-box h4 {
  font-size: 1.5rem;
}

@media (MAX-WIDTH:568PX) {
  .coupon-applied-box {
    width: 90%;
  }
}